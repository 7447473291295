import styled from 'styled-components';

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

export const Container = styled.div`
  margin: 2rem auto;
  height: 500px;

  img{
    width: 100%;
    height: 500px;
  }

  @media(max-width: 1024px){
    height: 490px;
    img{
      height: 490px;
    }
  }

  @media(max-width: 800px){
    height: 450px;
    img{
      height: 450px;
    }
  }
  @media(max-width: 600px){
    height: 400px;
    img{
    height: 400px;
    }
  }
  @media (max-width: 450px){
    height: 250px;
    img{
      height: 250px;
    }
  }

  .arrow{
    position: absolute;
    cursor: pointer;
    z-index: 10;
    margin: 15px;
    background-color: var(--primary);
    opacity: 0.5;
    transition: 300ms;
    border-radius: 5px;

    :hover{
      opacity: 1;
    }

    @media(max-width: 600px){
      display: none;
    }
  }

  .next{
    right: 0%;
    top:45%;
  }
  .prev{
    left: 0%;
    top: 45%;
  }

`;
