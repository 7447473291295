import styled from 'styled-components';

export const Container = styled.div`
	max-width: 1200px;
	margin: 0 auto;

	h1{
    border-bottom: 2px solid var(--secondary);
    padding-bottom: 1px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 50px;
		margin-left: 60px;
	  max-width: 230px;

		@media(max-width:600px){
			margin-top: 100px;
			margin-left: 15px;
		}

	}

`;
export const Form = styled.form`
	display: flex;
	flex-direction: column;

	margin-top: 30px;



	input{
		font-size: 18px;
		margin-bottom: 15px;
		background-color: white;
		width: 600px;
		height: 50px;
		padding: 5px;
		border-radius: 5px;
		color: black;

		:focus{
			border: 3px solid var(--secondary);
		}
		@media (max-width: 600px){
			width: 90vw;
		}

	}
	.send_btn{
		margin-top: 15px;
		background-color: var(--white);
		border: 2px solid var(--dark_bars);

		:hover{
			background-color: var(--secondary);
		}
	}

	.error{
		border-color: #f54242;
	}
`

export const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 70px;

	@media (max-width:600px){
		margin-top: 0;
	}
`
export const CarWrapper = styled.div`

	max-width: 700px;
	margin: 0 auto;
	list-style: none;

	display: flex;
	flex-direction: column;

	justify-content: center;
	align-items: flex-start;

	h2{
		font-size: 30px;
		border-bottom: 2px solid var(--secondary);
		padding: 0 5px;
	}

`

export const Car = styled.div`

	margin-top: 15px;
	background-color: whitesmoke;
	width: 600px;
	height: 250px;

	display: flex;
	flex-flow: row wrap;
	justify-content: space-around;
	align-items: center;
	border-radius: 15px;

	div{
		align-self: center;

		.disp{
			background-color: green;
		}
		.noDisp{
			background-color: brown;
		}
		p{
			color: white;
			font-size: 20px;
			font-weight: bolder;
			text-align: center;
			padding: 5px 0;
		}
	}

	h2{
		color: black;
		font-size: 25px;
		border-bottom: none;
	}

	h3{
		color: black;
		text-align: center;
		font-size: 25px;
	}
	.noPrice{
		display: none;
	}

	iframe{
		background-color: var(--dark_bars);
		border-radius: 10px;
		width: 250px;
		height: 220px;
	}

`