import React,{ useState, useEffect } from 'react';
import db from '../../server/firebaseConnection'
import { Container, SliderWrapper, CarBox, CarWrapper,TitleWrapper } from './Styles';
import { Link } from 'react-router-dom';

function SliderNC() {

  const [ car, setCar ] = useState([])

  useEffect(() => {
    const carRef = db.collection('carros')

    async function loadCar(){
      await carRef.onSnapshot((doc)=>{

        let car = []

        doc.forEach((i)=>{

          car.push(
            {
              id: i.id,
              model: i.data().model,
              brand: i.data().brand,
              image: i.data().i360,
              disp: i.data().disp,
              price: i.data().price,
              year: i.data().year,
              banner: i.data().banner
            }
          )
        })

        setCar(car)


      })
    }
    loadCar()
  }, [])

  return (
    <Container>
      <SliderWrapper>
        <TitleWrapper>
          <h1>Carros</h1> 
          <Link className='btn' to='/search'>ver todos</Link>
        </TitleWrapper>

        <CarWrapper>
          {
            car.map((c)=>{
              return(
                <li key={c.id}>
                  <Link className='btn' to={`/carInfo/${c.id}`}>
                  <CarBox>
                    {
                      c.banner ? <img src={c.banner} alt="banner" />
                      : <iframe src={c.image} title='image banner'/>
                    }
                    <h2>{c.brand} {c.model} <br/>{c.year}</h2>

                    {
                      c.disp === true ? <p>{c.price}</p> : <p className='noDisp'>Vendido</p>
                    }

                  </CarBox>
                  </Link>
                </li>
              )
            })
          }
        </CarWrapper>

      </SliderWrapper>
    </Container>
  );
}

export default SliderNC;