import React from 'react'

import {Routes, Route} from 'react-router-dom'

import Home from '../../pages/Home'
import CarPage from '../../pages/carInfo'
import Sobre from '../../pages/Sobre'
import Menu from '../Menu'
import Contato from '../../pages/Contato'
import Search from '../../pages/Search'
import Footer from '../Footer'
import Reserve from '../../pages/Reserve'
import Support from '../../pages/Support'

function Router() {
    return (
        <Routes>
            <Route exact path='/' element={<Home />}/>
            <Route exact path='/carInfo/:id' element={<CarPage />}/>
            <Route exact path='/sobre' element={<Sobre />}/>
            <Route exact path='/contato' element={<Contato />}/>
            <Route exact path='/search' element={<Search />}/>
            <Route exact path='/reserve/:id' element={<Reserve />}/>
            <Route exact path='/support' element={<Support />}/>
            <Route path='/*' element={()=>{
                return(
                    <div>
                        <Menu/>
                        <h1>em breve</h1>

                        <Footer />
                    </div>
                )
            }}/>
        </Routes>
    )
}

export default Router
