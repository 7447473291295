import React, {useState} from 'react'
import { Link } from 'react-router-dom';
import Logo from '../../images/logoHeader-new.png'
import LogoShort from '../../images/logo-short.png'
import { Container, Wrapper, NavWrapper } from './styles';

import { GoThreeBars, GoX, GoSearch } from 'react-icons/go';

const links = [
    {route: "/", label: "Home"},
    {route: "/sobre", label: "Sobre"},
    {route: "/contato", label: "Contato"}
]

function Menu() {

    const [openMenu, setOpenMenu] = useState(false)

    const rederLink = () => {

        return(
            links.map( link =>
                <Link key={link.route} to={link.route} className='link-menu'>
                    {link.label}
                </Link>
            )
        )
    }

    const HanddleOpen = () => {
        setOpenMenu(!openMenu)
    }

  return (
      <>
        <Container>
            <a href="/">
                <img src={Logo} alt="logo"/>
            </a>
        </Container>

        <Wrapper className = {openMenu !== true ? '' : 'open' }>

            <button className='btn-menu' onClick={HanddleOpen}>
                {
                openMenu !== true ?
                <GoThreeBars size='30' className='menu-bars'/>:
                <GoX size='30' className='menu-bars'/>
                }
                
            </button>



            <a  href="/"><img className='logoShort' src={LogoShort} alt='logo'/></a>

           <NavWrapper className={openMenu !== true ? '' : 'open'}>
            <ul>
                <li>{rederLink()}</li>
            </ul>
           </NavWrapper>

            <div className='search_area'>
                <Link to='/search' className='search-btn'>
                    <GoSearch />
                </Link>
            </div>

        </Wrapper>
    </>
  );
}

export default Menu;