import styled from 'styled-components';

export const Container = styled.div`
  
  margin: 15px;


  .view{
    display: flex;

    .btn-more{
      border-bottom: 1px solid var(--dark_bars);
    }
  }

`
export const BarWrapper = styled.div`

  display: flex;
  justify-content: center;

  margin: 0 auto;
  max-width: 1000px;
  background-color: var(--dark_bars);

`
export const Bar = styled.div`

  padding: 10px;

  p{
    font-weight: bolder;
    font-size: 18px;
  }

`
export const InfoWrapper = styled.div`

  display: none;
  max-width: 1000px;
  margin: 0 auto;
  background-color: var(--dark_bars);

  padding: 15px;

  justify-content: space-around;

  border-bottom: 1px solid var(--light_bars);
`
export const Info = styled.div`

  border-left: 1px solid gray;

  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 10px;

  width: 45%;

  h2{
    margin: 5px 0;
    font-size: 25px;
  }
  p{
    font-size: 17px;
    margin-top: 2px;
    font-weight: 100;
  }
  p.__cep{
    margin-top: 5px;
    font-weight: bold;
  }

  .btn{
    text-decoration: none;

    padding: 8px;
    background-color: var(--primary);
    margin: 5px;

    :hover{
      opacity: 0.8;
      color: var(--secondary);
    }
  }

`
export const BtnWrapper = styled.div`


  display: flex;
  justify-content: center;

`
export const Btn = styled.button`

  width: 150px;
  padding: 5px 0;

  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  border-bottom: 1px solid var(--light_bars);

  background-color: var(--dark_bars);

`