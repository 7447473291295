import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { 
  Container,
  BarWrapper,
  Bar,
  InfoWrapper,
  Info,
  BtnWrapper,
  Btn
} from './styles';

function Footer() {

  const [moreView, SetMoreView] = useState(true)

  const handleMore = (() => {
    SetMoreView(!moreView)
  })

  return(
    <>
    <Container>

      <BtnWrapper className={moreView === true ? '' : 'view'}>
        {moreView === true ? 
        <Btn className='btn-more' onClick={handleMore} >ver mais</Btn>:
        <Btn className='btn-more' onClick={handleMore}>ver menos</Btn>
        }
      </BtnWrapper>

      <InfoWrapper className={moreView === true ? '' : 'view'}>
        <Info>
          <h2>Endereço:</h2>
          <p>Rua algusto Rolim, 410</p>
          <p>São Jose, Mairinque - SP</p>
          <p className='__cep'>cep - 18.120-000</p>
        </Info>

        <Info>
        <h2>Contato:</h2>
        <Link className='btn' to='/contato'>Fale conosco</Link>
        <Link className='btn' to='/support'>Algum problema?</Link>
        </Info>
      </InfoWrapper>

      <BarWrapper>
        <Bar>
          <p>CNPJ - 427182600001-47</p>
        </Bar>
      </BarWrapper>

    </Container>
    </>
  )
}

export default Footer;