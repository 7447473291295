import React from 'react'
import { Container, AppWrapper } from './styles';

import Menu from '../../components/Menu'
import SliderHome from '../../components/SliderHome';
import SliderNC from '../../components/SliderNC';

import gp from '../../images/googlePlay.png'
import AS from '../../images/appleStore.png'
import Footer from '../../components/Footer';

function Home() {
  document.title = "Home | EllaCarAutos"

  return(
    <>
      <Menu />
      <Container>
        <SliderHome />
      </Container>
      <SliderNC />
      <br/><br/>

      <AppWrapper>
        <h1>Em breve também em nosso aplicativo</h1>

      <div className='btn-stores'>
        <div className='btn'><img src={gp} alt="Google Play" /></div>
        <div className='btn'><img src={AS} alt="Apple Store" /></div>
      </div>
      </AppWrapper>

    <Footer/>
    </>
  );
}

export default Home;