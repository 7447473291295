import firebase from "firebase";
import ('firebase/firestore');

const apiKey = process.env.REACT_APP_API_KEY || "AIzaSyDyjCFfDAmANDxJ0H_W6pwD5dNjYQqjCcg";
const authDomain = process.env.REACT_APP_AD || "goodca-database.firebaseapp.com";
const projectId = process.env.REACT_APP_PID || "goodca-database";
const storageBucket = "goodca-database.appspot.com";


firebase.initializeApp({
  apiKey: apiKey,
  authDomain: authDomain,
  projectId: projectId,
  storageBucket: storageBucket

})

const db = firebase.firestore()

export default db