import React, { useEffect, useState } from 'react';
import db from '../../server/firebaseConnection'
import { Link } from 'react-router-dom';
import Menu from '../../components/Menu';
import Footer from '../../components/Footer';

import {
  Container,
  SearchBar,
  Wrapper,
  CardsWrapper
} from './styles';
import { CarBox, CarWrapper } from './styles';

function Search() {
  document.title = 'Pesquisa | EllaCarAutos'

  const [carros, setCarros] = useState([])
  const [search, setSearch] = useState('')


  useEffect(() => {

    async function loadCars(){
      await db.collection('carros').onSnapshot((doc)=>{
        let car = []

        doc.forEach((i)=>{
          car.push({
            id: i.id,
            disp: i.data().disp,
            brand: i.data().brand,
            model: i.data().model,
            image: i.data().i360,
            price: i.data().price,
            year: i.data().year,
            banner: i.data().banner,
            nameCar: `${i.data().brand} ${i.data().model} ${i.data().year}`
          })
        })

        setCarros(car)
      })
    }

    loadCars()
  },[])

  
  var lowerSearch = search.toLowerCase()
  const Searching = carros.filter((c) => c.nameCar.toLowerCase().includes(lowerSearch)) 


  return(
    <>

    <Menu />
      <Container>
        <SearchBar placeholder='Pesquisar...' onChange={(e) => setSearch(e.target.value)} />
        <Wrapper>
          <div className='carros'>
            <CarWrapper>
            {
              search === null ?

              carros.map((c)=>{
                return(
                  <li key={c.id}>
                    <Link className='btn' to={`/carInfo/${c.id}`}>
                    <CarBox>
                    {
                      c.banner ? <img src={c.banner} alt="banner" />
                      : <iframe src={c.image} title='image banner'/>
                    }
                      <h2>{c.brand} {c.model} <br/>{c.year}</h2>

                      {
                        c.disp === true ? <p>{c.price}</p> : <p className='noDisp'>Vendido</p>
                      }

                    </CarBox>
                    </Link>
                  </li>
                )
              })
              :
              Searching.map((c)=>{
                return(
                  <li key={c.id}>
                    <Link className='btn' to={`/carInfo/${c.id}`}>
                    <CarBox>
                    {
                      c.banner ? <img src={c.banner} alt="banner" />
                      : <iframe src={c.image} title='image banner'/>
                    }
                      <h2>{c.brand} {c.model} <br/>{c.year}</h2>

                      {
                        c.disp === true ? <p>{c.price}</p> : <p className='noDisp'>Vendido</p>
                      }

                    </CarBox>
                    </Link>
                  </li>
                )
              })
            }
          </CarWrapper>
          </div>
        </Wrapper>
      </Container>

      <Footer />

    </>
  );
}

export default Search;