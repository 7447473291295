import styled from 'styled-components';

export const Container = styled.div`
  background-color: var(--dark_bars);
`;
export const SliderWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 15px 0;
  .arrow{
    position: absolute;
    z-index: 10;
    cursor: pointer;
    :hover{
      background-color: rgb(255,228,0,0.9);
      border-radius: 5px;
    }
  }
  .next{
    top: 45%;
    right: -2%;
    @media (max-width:1024px){
      right: 0%;
    }
    @media (max-width:800px){
      right: -2%;
    }
  }
  .prev{
    top: 45%;
    left: -2%;
    @media (max-width:1024px){
      left: 0%;
    }
    @media (max-width:800px){
      left: -1%;
    }
  }
  h1{
    border-bottom: 2px solid var(--secondary);
    padding: 10px;
    max-width: 280px;
  }
`
export const CarBox = styled.div`


  margin: 5px 10px;
  background-color: var(--white);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  width: 220px;
  height: 310px;
  border-radius: 8px;

  h2{
    color: black;
    align-self: center;
    font-weight: bold;
    padding: 5px 0;
    justify-content: center;
    display: flex;
    text-align: center;
    font-size: 23px;
  }

  img{
    background-color: var(--dark_bars);
    max-width: 215px;
    max-height: 155px;
    align-self: center;
    margin: 5px;
    border-radius: 10px;
  }
  iframe{
    background-color: var(--dark_bars);
    max-width: 215px;
    max-height: 65%;
    align-self: center;
    margin: 5px;
    border-radius: 10px;
  }

  p{
    color: black;
    font-weight: bold;
    font-size: 30px;
    align-self: center;
    margin-bottom: 5px;
    color: #eb384a;
  }

  .noDisp{
    background-color: brown;
    border-radius: 15px;
    padding: 0 20px;
    color: white;
  }

`

export const CarWrapper = styled.ul`
  display: flex;
  flex-flow: row wrap;
  list-style: none;
  padding: 15px;
  overflow: hidden;
  
  width: 1200px;
  max-height: 650px;
  .btn{
    text-decoration: none;
  }
`
export const TitleWrapper = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
@media(max-width: 600px){
  flex-direction: column;
  align-items: start;
  .btn{
    margin-top: 15px;
    margin-left: 10px;
  }
}
.btn{
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
  margin-right: 15px;
  padding: 5px 15px;
  background-color: var(--secondary);
  border-radius: 20px;
  color: var(--primary);
}
`