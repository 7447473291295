import React, {useEffect, useState} from 'react';
import db from '../../server/firebaseConnection';
import { Link, useParams } from 'react-router-dom'
import Menu from '../../components/Menu'
import Footer from '../../components/Footer';
import { BiChevronDown, BiChevronUp } from "react-icons/bi";

import image1 from '../../images/carEX.jpg'
import image2 from '../../images/carEX2.jpg'

import { 
  Container,
  Wrapper,
  ModelWrapper,
  InfoWrapper,
  Info,
  InfoBlock,
  DesWrapper,
  Description,
  More,
  Model360,
} from './styles';
import CarImages from '../../components/CarImages';


export default function CarInfo() {

  const { id } = useParams()

  const [carro, setCarro] = useState([])
  const [model, setModel] = useState('')
  const [brand, setBrand] = useState('')
  const [images, setImages] = useState([])

  const [moreView, setMoreView] = useState(true)

  const handleMore = () =>{
      setMoreView(!moreView)
  }
  document.title = ` ${brand} ${model} | GoodCarHouse`

  useEffect(()=>{

    const carRef = db.collection('carros').doc(id)

    async function carLoad(){

        await carRef.get().then((doc)=>{
            if(doc.exists){

                let car = []

                car.push({
                    id: doc.id,
                    model: doc.data().model,
                    brand: doc.data().brand,
                    year: doc.data().year,
                    image: doc.data().i360,

                    alarm: doc.data().alarm,
                    cdAuto: doc.data().cdAuto,
                    engine: doc.data().engine,
                    km: doc.data().km,
                    ipva: doc.data().ipva,
                    hist: doc.data().hist,
                    multi: doc.data().multi,
                    dh: doc.data().dh,
                    AC: doc.data().AC,
                    eletricLock: doc.data().eletricLock,
                    bc: doc.data().bc,
                    farolMi: doc.data().farolMi,
                    senseRe: doc.data().senseRe,
                    camRe: doc.data().camRe,
                    swt: doc.data().swt,
                    financ: doc.data().financ,
                    license: doc.data().license,
                    disp: doc.data().disp,

                })

                setCarro(car)
                setModel(doc.data().model)
                setBrand(doc.data().brand)
                
            }else{
                console.log('carro não encontrado')
            }
        })

    }

    carLoad()
  })



return(
  <>
    <Container>
    <Menu/>

      {carro.map((i)=>{
          
      return(
      <>
        <Wrapper>


          <ModelWrapper>
            <div className='images_car'>
                <CarImages />
            </div>


            <h1>{i.brand} {i.model} | {i.year}</h1>
            <h3>{i.price}</h3>

            {
                i.disp === true ?
                <div className='area-compra'>
                    <Link to={`/reserve/${i.id}`} className='btn-compra'>Faça sua reserva</Link>
                </div>
                :
                <p className='noDisp'>Vendido</p>
            }


          </ModelWrapper>

          <InfoWrapper>
              <h1>Informações</h1>

              <Info>

                  <InfoBlock>
                      <div>
                          <h1>Alarme?</h1><br/>
                      </div>
                      <div>
                          <p>{i.alarm === true ? 'Sim' : 'Não'}</p>
                      </div>
                  </InfoBlock>

                  <InfoBlock>
                      <div>
                          <h1>Cambio automatico?</h1><br/>
                      </div>
                      <div>
                          <p>{i.cdAuto === true ? 'Sim' : 'Não'}</p>
                      </div>
                  </InfoBlock>

                  <InfoBlock>
                      <div>
                          <h1>Motor:</h1><br/>
                      </div>
                      <div>
                          <p>{i.engine === '' ? 'N/A' : i.engine}</p>
                      </div>
                  </InfoBlock>

                  <InfoBlock>
                      <div>
                          <h1>km/s rodados?</h1><br/>
                      </div>
                      <div>
                          <p>{i.km}</p>
                      </div>
                  </InfoBlock>

                  <InfoBlock>
                      <div>
                          <h1>Ano do veiculo:</h1><br/>
                      </div>
                      <div>
                          <p>{i.year}</p>
                      </div>
                  </InfoBlock>

                  <InfoBlock>
                      <div>
                          <h1>IPVA:</h1><br/>
                      </div>
                      <div>
                          <p>{i.ipva === true ? 'Pago' : 'A pagar'}</p>
                      </div>
                  </InfoBlock>
                  
              </Info>

          </InfoWrapper>
        </Wrapper>

        <DesWrapper>
          <Wrapper className='desk'>
            <h1>Descrição</h1>

            <Description>

              <p className={moreView === true ? '' : 'more'}>
                  Marca: {i.brand}<br/>
                  Modelo: {i.model}<br/> 
                  Motor: {i.engine === '' ? 'N/A' : i.engine}<br/>
                  Ano de fabricação: {i.year}<br/>
                  km/s rodados: {i.km}<br/>
                  <br/>
                  Histórico:<br/>
                  {i.hist}<br/>
                  <br/>
                  opcionais:<br/>
                  <br/>
                  Cambio: {i.cdAuto === true ? 'automático' : 'manual'}<br/>
                  Alerta: {i.alarm === true ? 'Sim' : 'Não'}<br/>
                  multimidia / DVD: {i.multi === true ? 'Sim' : 'Não'}<br/>
                  Direção hidraulica: {i.dh === true ? 'Sim' : 'Não'}<br/>
                  Ar condicionado: {i.AC === true ? 'Sim' : 'Não'}<br/>
                  Travas elétricas: {i.eletricLock ? 'Sim' : 'Não'}<br/>
                  Bancos de couro: {i.bc === true ? 'Sim' : "Não" }<br/>
                  Farol de milha: {i.farolMi === true ? 'Sim' : 'Não'}<br/>
                  Sensor de ré: {i.senseRe === true ? 'Sim' : 'Não'}<br/>
                  Camera de ré: {i.camRe === true ? 'Sim' : 'Não'}<br/>
                  <br/>
                  Aceita troca: {i.swt === true ? 'Sim' : 'Não'}<br/>
                  Aceita finaciamento: {i.financ === true ? 'Sim' : 'Não'}<br/>
                  IPVA: {i.ipva === true ? 'Pago' : 'A pagar' }<br/>
                  Licenciado: {i.license ? 'Sim' : 'Não'}
              </p>
              
              <More onClick={handleMore}>
              {
                  moreView === false ?
                  <>
                      <BiChevronUp size='20'/> <h2>ver menos</h2>
                  </>:
                  <>
                      <BiChevronDown size='20'/> <h2>ver mais</h2>
                  </>
              }</More>
            </Description>
          </Wrapper>
        </DesWrapper>

      </>
                  
      )
      })}
        
    </Container>

      <Footer />

  </>
);
}