import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 auto;
  max-width: 1230px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SearchBar = styled.input`
  margin: 5px;
  padding: 14px;
  width: calc(1200px - 10%);

  @media(max-width: 1199px){
    width: 90vw;
  }

  border: 2px solid;
  border-radius: 15px;

  background-color: var(--white);

  :focus{
    border-color: var(--secondary);
  }

  font-size: 16px;
  color: black;

`

export const Wrapper = styled.div`

display: flex;
align-items: center;
flex-direction: column;

/* .pagination{

  .invisible{
    opacity: 0.5;
  }

  display: flex;
  flex-direction: row;
  margin: 15px;

  li{
    padding: 5px;
    background-color: white;
    border: 2px solid var(--dark_bars);
    button{
      color: black;
    }
  }

  li + li{
    margin-left: 5px;
  }

  .pagination_item-active{
    border: 2px solid var(--white);
      background-color: var(--dark_bars);

      button{
        color: white;
        font-weight: bold;
      }
      :focus{
        outline: none;
      }
  }
} */

`

export const CarBox = styled.div`


  margin: 5px 10px;
  background-color: var(--white);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  width: 220px;
  height: 310px;
  border-radius: 8px;

  h2{
    color: black;
    align-self: center;
    font-weight: bold;
    padding: 5px 0;
    justify-content: center;
    display: flex;
    text-align: center;
    font-size: 23px;
  }

  img{
    background-color: var(--dark_bars);
    max-width: 215px;
    max-height: 155px;
    align-self: center;
    margin: 5px;
    border-radius: 10px;
  }
  iframe{
    background-color: var(--dark_bars);
    max-width: 215px;
    max-height: 65%;
    align-self: center;
    margin: 5px;
    border-radius: 10px;
  }

  p{
    color: black;
    font-weight: bold;
    font-size: 30px;
    align-self: center;
    margin-bottom: 5px;
    color: #eb384a;
  }

  .noDisp{
    background-color: brown;
    border-radius: 15px;
    padding: 0 20px;
    color: white;
  }

`

export const CarWrapper = styled.ul`

  display: flex;
  align-items: center;
  flex-flow: row wrap;
  list-style: none;
  padding: 15px;

  max-width: max-content;
  
  ul{
    width: max-content;
    justify-content: center;
  }

  .btn{
    text-decoration: none;
  }
`