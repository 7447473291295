import styled from "styled-components";

export const Container = styled.div`
    display: block;

    .area-compra{
        padding: 15px;
        display: flex;
        justify-content: center;

        .btn-compra{
            padding: 10px;
            margin: 10px;
            background-color: var(--secondary);
            color: var(--dark_bars);
            border-radius: 15px;
            font-weight: bold;
            font-size: 20px;
            cursor: pointer;
            transition: 0.4s;
            text-decoration: none;

            :hover{
                opacity: 0.8;
            }
        }
    }

`
export const Wrapper = styled.div`
    max-width: 1200px;
    margin: 0 auto;

    p.noDisp{
        background: brown;
        padding: 5px;
        font-size: 20px;
    }

`
export const ModelWrapper = styled.div`
    padding: 30px;
    justify-content: center;

    .area-compra{
        padding: 15px;

        .btn-compra{
            padding: 10px;
            margin: 10px;
            background-color: var(--secondary);
            color: var(--dark_bars);
            border-radius: 15px;
            font-weight: bold;
            font-size: 20px;
            cursor: pointer;
            transition: 0.4s;

            :hover{
                opacity: 0.8;
            }
        }
    }

    @media (max-width: 600px){
        padding: 5px;
    }

    display: block;
    text-align: center;

    h1{
        margin-top: 15px;
        font-size: 40px;
    }

    h3{
        margin-top: 5px;
        font-size: 35px;
        color: var(--secondary);
    }

    .images_car{
        margin-bottom: 60px;
    }
`

export const InfoWrapper = styled.div`

    display: inline-block;

    h1{
        border-bottom: 2px solid var(--secondary);
        padding-bottom: 1px;
        padding-left: 10px;
        padding-right: 10px;
        margin: 15px;
        max-width: 220px;
    }

`

export const Info = styled.div`
    display: flex;
    padding: 20px;
    flex-flow: row wrap;
    max-width: 550px;

    @media (max-width: 600px){
        justify-content: center;
    }

`

export const InfoBlock = styled.div`

    display: flex;
    flex-flow: column;


    width: 150px;
    height: 200px;
    background-color: #fafafafa;

    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    text-align: center;
    margin: 10px;
    border-radius: 15px;
    align-items: center;

    div{
        /* width: 50%; */
    }


    h1{
        color: #25252255;
        border-bottom: none;
        font-size: 20px;
    }

    p{
        color: black;
        font-size: 28px;
        font-weight: bolder;
    }

    @media (max-width: 600px){
        width: 43%;
        height: 180px;
    }
    @media (max-width: 320px){
        width: 80%;
        height: 200px;
        justify-content: center;
        align-items: center;

        h4{
            width: 150px;
            height: 150px;
        }
    }
    @media (max-width:545px){
        height: 280px;
        justify-content: center;
        align-items: center;

        h4{
            width: 120px;
            height: 120px;
        }
    }

`

export const DesWrapper = styled.div`

    background-color: var(--dark_bars);
    display: block;

    h1{
        border-bottom: 2px solid var(--secondary);
        padding-bottom: 1px;
        padding-left: 10px;
        padding-right: 10px;
        margin: 15px;
        max-width: 180px;
    }
    
    .desk{
        padding: 10px;
    }

    margin-bottom: 50px;

`
export const Description = styled.div`
    display: flex;
    flex-direction: column;

    padding: 15px;
    max-width: 670px;

    .more{
            height: 100%;
            border-bottom: none;
        }

    p{
        font-size: 20px;
        font-weight: bold;
        height: 200px;
        overflow-y: hidden;
        border-bottom: 2px ridge var(--secondary);
    }


`
export const More = styled.button`

`
export const Model360 = styled.iframe`

    width: 900px;
    height: 500px;
    padding: 0;
    margin: 0 auto;

    background-color: var(--dark_bars);

    display: flex;
    justify-content: center;

    @media (max-width: 600px){
        margin: 0;
        padding: 0;
        height: 250px;
        width: 100%;
    }

    @media (max-width: 750px){
        height: 350px;
    }

    @media (max-width: 320px){
        height: 210px;
        margin-top: 15px;
    }

    @media (max-width:360px){
        height: 250px;
    }


`