import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import db from '../../server/firebaseConnection';
import { SLiderWrapper, Wrapper360, Wrapper } from './styles';
import Slider from 'react-slick'

import { Model360 } from '../../pages/carInfo/styles';

function CarImages() {

  const [ alternate, setAlternate ] = useState(true)
  const [model, setModel] = useState("")
  const [images, setImages] = useState([])

  const { id } = useParams()


  const settings = {
    arrows: true,
    infinite: true,
    fade: true,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  useEffect(()=>{

    const carRef = db.collection('carros').doc(id)

    async function modelLoad(){
      await carRef.get().then((doc)=>{
        if(doc.exists){
          
          setModel(doc.data().i360)

          if(doc.data().images){
            setImages(doc.data().images)
          }

            
        }else{
          console.log('carro não encontrado')
        }
    })
    }

    modelLoad()
    console.log(model)
  }, [])

  return (
    <>
      <Wrapper>
        <div>
          <button className={alternate === true ? 'view ft' : ''} onClick={() => setAlternate(!alternate)}>
            Fotos
          </button>
          <button className={alternate === false ? 'view ft360' : ''} onClick={() => setAlternate(!alternate)}>
            360
          </button>
        </div>
      </Wrapper>
      {
        alternate === true ?
        <SLiderWrapper>
        <Slider {...settings}>
            {
              images.length > 0
              ?
              images.map((img)=>{
                return(
                  <div className='image'>
                    <img key={img.id} src={img.src} alt={img.id} />
                  </div>
                )
              })
              :
              <div className='image'>
                <p>Este carro ainda não possuí fotos comuns, por favor olhar o 360 do veículo!</p>
              </div>
            }
        </Slider>
        </SLiderWrapper> :

        <Wrapper360>
          <Model360 src={model}/>
        </Wrapper360>
      }


    </>
  );
}

export default CarImages;
