import React from 'react';
import Footer from '../../components/Footer';

import Menu from '../../components/Menu';

import { Container, Wrapper} from './styles';

function Sobre() {

  document.title = 'Sobre | EllaCarAutos'
  return(
    <>
      <Menu />

      <Container>
        <Wrapper>
          <h1>Sobre</h1>

          <p>
          A <strong>ELLA CAR AUTOS</strong> é uma empresa familiar, com grande experiência na 
          compra, venda e consignação de veículos, que busca unir qualidade e bons preços 
          para seus clientes, trabalhando somente com veículos vistoriados 
          e de excelente procedência, garantindo assim o máximo segurança, 
          na compra de qualquer um de nossos veículos.
          </p>
        </Wrapper>
      </Container>

      <Footer />
    </>
  )
}

export default Sobre;