import React,{ useState, useEffect } from 'react';
import emailjs from 'emailjs-com'
import db from '../../server/firebaseConnection';

import Footer from '../../components/Footer';
import Menu from '../../components/Menu';

import { Container, Form, Wrapper, CarWrapper, Car } from './styles';
import { useParams } from 'react-router-dom';

function Reserve() {

  document.title = 'Reservar | EllaCarAutos'

  const { id } = useParams()

  const [cpf, setCpf] = useState('')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [tel, setTel] = useState('')

  const [carro, setCarro] = useState([])
  const [model, setModel] = useState('')
  const [brand, setBrand] = useState('')
  const [year, setYear] = useState('')
  const [image, setImage] = useState(null)
  const [disp, SetDisp] = useState(null)
  const [price, setPrice] = useState('')

  function sendForm(e){

    console.log(`${name} ${email} ${cpf} ${tel}`)

    e.preventDefault();

    if( name === '' || cpf === '' || email === '' || tel === ''){
      alert('Por favor preencha os campos corretamente!')
    }else{

      emailjs.sendForm('service_zbxtt7h', 'template_xbfksaf', e.target ,'user_KTQdFYNZ3WFqJqKhy31n7')
      .then((result) => {
        alert('Pedido enviado com sucesso!')

        setTel('')
        setName('')
        setEmail('')
        setCpf('')

        console.log(result.text)

      }, (error)=>{
        console.log(error.text)
      })
    }

  }

  useEffect(() => {

    const carRef = db.collection('carros').doc(id)

    async function carLoad(){

        await carRef.get().then((doc)=>{
            if(doc.exists){

                let car = []

                car.push(doc.data())

                setCarro(car)
                setModel(doc.data().model)
                setBrand(doc.data().brand)
                setYear(doc.data().year)
                setImage(doc.data().i360)
                SetDisp(doc.data().disp)
                setPrice(doc.data().price)
                
            }else{
                console.log('carro não encontrado')
            }
        })

    }

    carLoad()
})

  function Mask_cpf(){

    let cpfNumber = document.getElementById('cpfN')
    
    let v = cpfNumber.value;
   
    if(isNaN(v[v.length-1])){
      cpfNumber.value = v.substring(0, v.length-1);
      return;
    }
   
   cpfNumber.setAttribute("maxlength", "14");
   if (v.length == 3 || v.length == 7) cpfNumber.value += ".";
   if (v.length == 11) cpfNumber.value += "-";

  }

  function Mask_tel(){

    let telNumber = document.getElementById('telN')

    let v = telNumber.value

    if(isNaN(v[v.length-1])){
      telNumber.value = v.substring(0, v.length-1);
      return;
    }
   
   telNumber.setAttribute("maxlength", "15");
   if (v.length == 1) telNumber.value = `(${telNumber.value}`;
   if (v.length == 3) telNumber.value = `${telNumber.value})`;
   if (v.length == 4) telNumber.value += ' ';
   if (v.length == 10) telNumber.value += "-";

  }


  return(
    <>
      <Menu />

      <Container>

        <h1>Fazer reserva</h1>

        <Wrapper>
          <Form onSubmit={sendForm}>

            <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            type="text"
            placeholder='Nome Completo*'
            name='name'
            />
            
            <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="e-mail"
            placeholder='E-mail*'
            name='email'
            />
            
            <input
            value={cpf}
            onKeyUp={Mask_cpf}
            onChange={(e) => setCpf(e.target.value)}
            type="text"
            placeholder='CPF*'
            name='cpf'
            autoComplete={false}
            maxLength='11'
            id='cpfN'
            />

            <input
            value={tel}
            onKeyUp={Mask_tel}
            onChange={(e) => setTel(e.target.value)}
            type="tel"
            placeholder='telefone (Whatsapp)*'
            name='tel'
            id='telN'
            autoComplete={false}
            />

            <input style={{display: 'none'}} type="text" name='brand' value={brand}/>
            <input style={{display: 'none'}} type="text" name='model' value={model}/>

            <input type="submit" value='Finalizar pedido' className='send_btn'/>


        <CarWrapper>
          <h2>carro escolhido</h2>
          {
            carro.map(()=>{

              return(
                <li>
                  <Car>
                    
                    <iframe src={image}/>
                    <div>
                      <h2 >{brand} {model} {year}</h2>
                      <p className={disp === true ? 'disp' : 'noDisp'}>{disp === true ? 'disponivel' : 'Vendido'}</p>
                      <h3 className={disp === true ? '' : 'noPrice'}>{price}</h3>
                    </div>
                  </Car>
                </li>
              )

            })
          }
        </CarWrapper>
          </Form>

        </Wrapper>

      </Container>

      <Footer/>
    </>
  )
}

export default Reserve;