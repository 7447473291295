import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto ;
`;

export const AppWrapper = styled.div`

  display: flex;
  align-items: center;
  flex-direction: column;

  padding: 15px;

  h1{
    font-size: 40px;
    font-weight: bold;
    font-style: italic;
    font-family: 'Gothic Medium', sans-serif;
    padding: 15px;
    text-align: center;
  }

  .btn-stores{
    display: flex;
    .btn{
      margin: 10px;
      @media(max-width: 450px){
        display: flex;
        justify-content: center;
      }
    img{
      width: 200px;
    }
  }
}


`