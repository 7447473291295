import styled from 'styled-components';

export const Container = styled.div`
	max-width: 1200px;
	max-height: auto;
	margin: 0 auto;

`;

export const Wrapper = styled.div`
	  display: flex;
		justify-content: center;
		flex-direction: column;
		margin-top: 20px;
		padding: 0 15px;
		padding-bottom: 15px;


		background-color: var(--dark_bars);

		@media(max-width:600px){
			margin: 15px 5px;
		}

	h1{
		border-bottom: 2px solid var(--secondary);
    padding-bottom: 1px;
    padding-left: 10px;
    padding-right: 10px;
    margin: 15px;
		max-width: 115px;
	}

	p{
		font-size: 21px;
		width: 60%;

		@media(max-width:600px){
			width: 100%;
		}
	}
`