import styled from 'styled-components';
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

export const SLiderWrapper = styled.div`
  margin: 0 auto;
  height: 500px;
  max-width: 1000px;

  img{
    width: 900px;
    height:500px;
    z-index: 1;
  }

  div.image{
    margin: 0 auto;
    max-width: 900px;
    height: auto;

    p{
      font-size: 30px;
      font-weight: bold;
      text-align: center;
      margin-top: 30px;
      margin-bottom: 30px;
      padding: 30px;
      color: red;
      border: 3px solid white;  
    }
    img{
      width: 900px;
      height: 500px;
      border: 3px solid white;
    }
  }
`
export const Wrapper360 = styled.div`
`
export const Wrapper = styled.div`

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  div{

    button{
      background-color: var(--dark_bars);
      padding: 5px;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      border: 2px solid white;
      font-size: 20px;
      opacity: 0.5;
    }

    .view{
      opacity: 1;
      border-color: var(--secondary);
    }
    .ft360{
      border-top-right-radius: 5px;
      border-top-left-radius: 0;
    }
    .ft{
      border-top-right-radius: 0;
      border-top-left-radius: 5px;
    }
  }

`