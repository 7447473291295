import {GlobalStyles} from './GlobalStyles'

import Routes from './components/Router'
import {BrowserRouter as Router} from 'react-router-dom'


function App() {
  return (
    <>
      <Router>
        <Routes/>
      </Router>

      <GlobalStyles/>
    </>
  );
}

export default App;
