import React,{useState} from 'react';
import emailjs from 'emailjs-com'

import { Container, Form, Wrapper } from './styles';

import Footer from '../../components/Footer';
import Menu from '../../components/Menu'

function Contato() {

  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [message, setMessage] = useState('')

  document.title = 'Contato | EllaCarAutos'

  function SendEmail(e){

    console.log( `${email} ${name} ${message}` )

    e.preventDefault();

    if(email === '' || name === '' || message === ''){
      alert('Por favor preencha os campos corretamente!')
    }else{
      emailjs.sendForm("service_drj81dg","goodCarHouse_contato", e.target, 'user_0moxEQJIcPbVNbjEZEblR')
      .then((result) => {
          alert('email enviado com sucesso!')

          setEmail('')
          setName('')
          setMessage('')
          
          console.log(result.text);
      }, (error) => {
          alert('Algo deu Errado tente novamente mais tarde!')
          console.log(error.text);
      });
    }

  }

  return(

    <>
    <Menu/>
      <Container>

        <h1>contato</h1>
        <Wrapper>
          <Form onSubmit={SendEmail}>
            <input value={name} onChange={ e => setName(e.target.value) } type="text" placeholder='nome completo' name='from_name'/>
            <input value={email} onChange={ e => setEmail(e.target.value) } type='text' placeholder='email' name='email'/>

            <textarea value={message} onChange={ e=> setMessage(e.target.value) } className='msg' placeholder='Sua mensagem' name='message' />
            <input type="submit" value='Enviar' className='send_btn'/>
          </Form>
        </Wrapper>
      </Container>

      <Footer />
    </>
  );
}

export default Contato;