import React,{useState} from 'react';
import Slider from 'react-slick'
import {BiChevronLeft, BiChevronRight,} from 'react-icons/bi'

import { Container } from './styles';

import vistCar from '../../images/sliderImgs/carroVistoriado.png'
import bestSP from '../../images/sliderImgs/bestSP.png'
import lowCust from '../../images/sliderImgs/custoBaixo.png'
import cxb from '../../images/sliderImgs/custoBeneficio.png'

const images = [bestSP, vistCar, lowCust, cxb]

function SliderHome() {

  const NextArrow = ({onClick}) => {
    return(
      <div className='arrow next' onClick={onClick}>
        <BiChevronRight size = '40' />
      </div>
    )
  }
  const PrevArrow = ({onClick}) => {
    return(
      <div className='arrow prev' onClick={onClick}>
        <BiChevronLeft size = '40' />
      </div>
    )
  }

  const [ImageIndex, setImageIndex] = useState(0)

  const settings = {
    arrows: true,
    infinite: true,
    autoplay: true,
    speed: 400,
    pauseOnHover: false,
    autoplaySpeed: 100000,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow:<NextArrow />,
    prevArrow:<PrevArrow />,
    beforeChange:(current, next) => setImageIndex(next),
    cssEase: "linear",
  };

  return (
    <Container>
      <Slider {...settings}>
        {images.map((img)=>(
          <div>
            <img src={img} alt={img} />
          </div>
        ))}
      </Slider>
    </Container>
  );
}

export default SliderHome;