import styled from 'styled-components';

export const Container = styled.div`
	max-width: 1200px;
	margin: 0 auto;

	h1{
    border-bottom: 2px solid var(--secondary);
    padding-bottom: 1px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 50px;
		margin-left: 60px;
	  max-width: 140px;

		@media(max-width:600px){
			margin-top: 100px;
			margin-left: 15px;
		}

	}

`;
export const Form = styled.form`
	display: flex;
	flex-direction: column;

	margin-top: 30px;



	input{
		font-size: 18px;
		margin-bottom: 15px;
		background-color: white;
		width: 500px;
		height: 50px;
		padding: 5px;
		border-radius: 5px;
		color: black;

		:focus{
			border: 3px solid var(--secondary);
		}
		@media (max-width: 600px){
			width: 90vw;
		}

	}
	.send_btn{
		margin-top: 15px;
		background-color: var(--white);
		border: 2px solid var(--dark_bars);

		:hover{
			background-color: var(--secondary);
		}
	}
	.msg{
			width: 500px;
			height: 250px;
			background-color: white;
			padding: 15px;
			border-radius: 5px;
			font-size: 18px;
			color: black;

			@media (max-width: 600px){
			width: 90vw;
		}

			:focus{
			border: 3px solid var(--secondary);
		}
	}

	.error{
		border-color: #f54242;
	}
`

export const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 70px;

	@media (max-width:600px){
		margin-top: 0;
	}
`
